class Plantilla {
  constructor({
    id, titulo, type, campos, updated_at, created_at, body_html, estado,
  } = {}) {
    this.id = id
    this.titulo = titulo
    this.campos = campos
    this.updated_at = updated_at
    this.created_at = created_at
    this.body_html = body_html
    this.estado = estado
    this.type = type
  }
}

export default Plantilla
