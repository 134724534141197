import { BASE_API } from '@/api/v1'
import HistoricoMaterial from '@/models/HistoricoMaterial'
import BaseController from './BaseController'

class HistoricoMaterialesController extends BaseController {
    async getAll(payload) {
        const res = await super.read(`${BASE_API}/historico-materiales?page=${payload.query.page}`, payload)
        const materiales = res.data.map(obj => new HistoricoMaterial(obj))
        return {
            total: res.total,
            data: materiales,
        }
    }
}
export default HistoricoMaterialesController
