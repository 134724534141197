class Departamento {
  constructor({
    id, parent_id, titulo, descripcion, descripcion_custom, estado, num_empleados, created_at, updated_at,
  } = {}) {
    this.id = id
    this.parent_id = parent_id
    this.titulo = titulo
    this.descripcion = descripcion
    this.descripcion_custom = descripcion_custom
    this.estado = estado
    this.num_empleados = num_empleados
    this.created_at = created_at
    this.updated_at = updated_at
  }
}

export default Departamento
