import { BASE_API } from "@/api/v1";
import BaseController from "./BaseController";
import Trabajo from "@/models/Trabajo";

class TrabajoController extends BaseController {
  async createTrabajo(object) {
    return super.create(`${BASE_API}/partes-trabajo`, object);
  }

  async uploadFiles(object) {
    let formData = new FormData()
    object.raw_files.forEach(file => {
      formData.append('raw_files[]', file)
    });

    formData.append('fk_partes_trabajo', object.fk_partes_trabajo)

    object.file_mapper.forEach(file => {
      formData.append('file_mapper[]', JSON.stringify(file))
    })

    object.files_to_delete.forEach(file => {
      formData.append('files_to_delete[]', JSON.stringify(file))
    })

    formData.append('image_mapper', JSON.stringify(object.image_mapper))
    formData.append('raw_image', object.raw_image)

    return super.sendFile(`${BASE_API}/partes-trabajo-upload-files`, formData)
  }

  async updateTrabajo(object) {
    return super.create(`${BASE_API}/partes-trabajo`, object);
  }

  async updateEstado(payload) {
    return super.create(`${BASE_API}/partes-trabajo-estado`, payload)
  }

  async getTrabajoStatistics() {
    return super.readOne(`${BASE_API}/partes-trabajo-stats`)
  }

  async getEncomiendas() {
    return super.read(`${BASE_API}/encomiendas`, { body: {} })
  }

  async getAllTrabajos(payload) {
    const res = await super.read(
      `${BASE_API}/partes-trabajos?page=${payload.query.page}`,
      payload
    );
    const trabajos = this.mapToArray(
      new Map(res.data.map((obj) => [obj.id, new Trabajo(obj)]))
    );
    return {
      total: res.total,
      data: trabajos,
    };
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/partes-trabajo/${id}`);
    return new Trabajo(res[0]);
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/partes-trabajos`, payload);
    const trabajos = this.mapToArray(
      new Map(res.map((obj) => [obj.id, new Trabajo(obj)]))
    );
    return {
      total: trabajos.length,
      data: trabajos,
    };
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/partes-trabajo/delete/${id}`);
  }

  mapToArray(map) {
    return Array.from(map.values());
  }
}
export default TrabajoController;
