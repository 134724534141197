import { BASE_API } from '@/api/v1'
import Departamento from '@/models/Departamento'
import BaseController from './BaseController'

class DepartamentoController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/departamento`, object)
  }

  async update(object) {
    return super.update(BASE_API, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/departamentos?page=${payload.query.page}`, payload)
    const departamentos = this.mapToArray(new Map(res.departamentos.data.map(obj => [obj.id, new Departamento(obj)])))
    return {
      total: res.total,
      data: departamentos,
      countEmpleado: res.count_empleados,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/departamentos`, payload)
    const departamentos = this.mapToArray(new Map(res.departamentos.map(obj => [obj.id, new Departamento(obj)])))
    return {
      total: departamentos.length,
      data: departamentos,
    }
  }

  async getById(id) {
    const res = await super.read(`${BASE_API}/${id}`)
    return new Departamento(res)
  }

  async delete(id) {
    return super.delete(`${BASE_API}/departamento/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default DepartamentoController
