class Proveedor {
  constructor({
    id, nombre_comercial, nombre_fiscal, cif, contacto, telefono, movil, email, direccion, poblacion, provincia, estado, created_at, updated_at,
  } = {}) {
    this.id = id
    this.nombre_comercial = nombre_comercial
    this.nombre_fiscal = nombre_fiscal
    this.cif = cif
    this.contacto = contacto
    this.telefono = telefono
    this.movil = movil
    this.email = email
    this.direccion = direccion
    this.poblacion = poblacion
    this.provincia = provincia
    this.estado = estado
    this.created_at = created_at
    this.updated_at = updated_at
  }
}

export default Proveedor
