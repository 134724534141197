import { BASE_API } from '@/api/v1'
import Empleado from '@/models/Empleado'
import BaseController from './BaseController'

class EmpleadoController extends BaseController {
  async importar(file) {
    const formData = new FormData()
    formData.append('import_excel', file)
    return super.sendFile(`${BASE_API}/importar-empleados`, formData)
  }

  async create(object) {
    /* eslint-disable-next-line no-param-reassign */
    if (object.fk_dpto) object.fk_dpto = object.fk_dpto.key
    return super.create(`${BASE_API}/editar-empleado`, object)
  }

  async update(object) {
    return super.update(BASE_API, object)
  }

  async getAll(payload) {
    const response = await super.read(`${BASE_API}/empleados?page=${payload.query.page}`, payload)
    const empleados = this.mapToArray(new Map(response.data.map(obj => [obj.id, new Empleado(obj)])))
    return {
      total: response.total,
      last_page: response.last_page,
      data: empleados,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/empleado/${id}`)
    const empl = new Empleado(res[0])
    empl.is_user = empl.is_user === 1
    empl.estado = empl.estado === 1
    empl.has_uniform = empl.has_uniform === 1
    return empl
  }

  async updateEmpleadoUniformStatus(empleadoId, uniformStatus) {
    return super.readOne(`${BASE_API}/update-empleado-uniform/${empleadoId}/${uniformStatus ? 1 : 0}`)
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default EmpleadoController
