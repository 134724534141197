import { BASE_API } from '@/api/v1'
import User from '@/models/User'
import BaseController from './BaseController'

class UserController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/edit-user`, object)
  }

  async update(object) {
    return super.update(BASE_API, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/get-users?page=${payload.query.page}`, payload)
    const users = this.mapToArray(new Map(res.users.data.map(obj => [obj.id, new User(obj)])))
    return {
      total: res.users.total,
      data: users,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/get-users`, payload)
    const users = this.mapToArray(new Map(res.users.map(obj => [obj.id, new User(obj)])))
    return {
      total: users.length,
      data: users,
    }
  }

  async getById(id) {
    const res = await super.read(`${BASE_API}/${id}`)
    return new User(res)
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default UserController
