import { BASE_API } from '@/api/v1'
import Pedido from '@/models/Pedido'
import BaseController from './BaseController'

class PedidoController extends BaseController {
    async create(object) {
        return super.create(`${BASE_API}/pedido`, object)
    }

    async getAll(payload) {
        const res = await super.read(`${BASE_API}/pedidos?page=${payload.query.page}`, payload)
        const pedidos = this.mapToArray(new Map(res.data.map(obj => [obj.id, new Pedido(obj)])))
        return {
            total: res.total,
            data: pedidos,
        }
    }

    async getById(id) {
        const res = await super.readOne(`${BASE_API}/pedido/${id}`)
        return new Pedido(res)
    }

    async changeStatus(data) {
        const res = await super.create(`${BASE_API}/pedido-status/${data.id}`,
          { estado: data.estado, approved_by: data.approved_by, detalles: data.detallePedidos })
        return new Pedido(res)
    }

    async duplicatePedido(data) {
        const res = await super.create(`${BASE_API}/pedido-duplicate/${data}`)
        return new Pedido(res)
    }

    async deleteById(id) {
        return super.delete(`${BASE_API}/pedidos/delete/${id}`)
    }

    async printPedido(id) {
        return super.readOne(`${BASE_API}/pedido-print/${id}`)
    }


    /* eslint-disable-next-line class-methods-use-this */
    mapToArray(map) {
        return Array.from(map.values())
    }
}
export default PedidoController
