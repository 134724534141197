import axios from 'axios'
import axiosConfig from '@/auth/axios'

class Controller {
  constructor() {
    axiosConfig.setHeaders()
  }

  /* eslint-disable-next-line class-methods-use-this */
  async sendFile(path, payload) {
    const res = await axios.post(path, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })
    return res
  }

  /* eslint-disable-next-line class-methods-use-this */
  async create(path, payload) {
    const res = await axios.post(path, payload)
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })
    return res
  }

  /* eslint-disable-next-line class-methods-use-this */
  async read(path, payload) {
    const res = await axios.post(path, payload.body, {
      headers: payload.headers,
    })
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  /* eslint-disable-next-line class-methods-use-this */
  async readOne(path, payload) {
    const res = await axios.get(path, payload)
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  /* eslint-disable-next-line class-methods-use-this */
  async update(path, payload) {
    const res = await axios.patch(path, payload)
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })

    return res
  }

  /* eslint-disable-next-line class-methods-use-this */
  async delete(path, payload) {
    return axios.delete(path, payload)
      .then(res => res)
      .catch(err => {
        throw new Error('New error message', { cause: err })
      })
  }
}

export default Controller
