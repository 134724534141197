import { BASE_API } from '@/api/v1'
import Plantilla from '@/models/Plantilla'
import BaseController from './BaseController'

class PlantillaController extends BaseController {
  async getAll(payload) {
    const response = await super.read(`${BASE_API}/plantillas?page=${payload.query.page}`, payload)
    const plantillas = this.mapToArray(new Map(response.data.map(obj => [obj.id, new Plantilla(obj)])))

    return {
      total: response.total,
      data: plantillas,
    }
  }

  async listWithoutPagination(payload) {
    const response = await super.read(`${BASE_API}/plantillas`, payload)
    const plantillas = this.mapToArray(new Map(response.map(obj => [obj.id, new Plantilla(obj)])))

    return {
      total: plantillas.length,
      data: plantillas,
    }
  }

  async save(object) {
    const form = new FormData()
    if (object.id) form.append('id', object.id)
    form.append('titulo', object.titulo)
    form.append('campos', object.campos)
    form.append('type', object.type)
    form.append('estado', object.estado ? 1 : 0)
    form.append('body_html', object.body_html)
    return super.create(`${BASE_API}/plantilla`, form)
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/plantillas/edit/${id}`)
    const plantilla = new Plantilla(res[0])
    plantilla.estado = plantilla.estado === 1
    return plantilla
  }

  async printPreview(id) {
    const res = await super.readOne(`${BASE_API}/plantillas/print-preview/${id}`)
    return res
  }

  async delete(id) {
    return super.delete(`${BASE_API}/plantillas/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default PlantillaController
