class Tarea {
    constructor({
        id, created_at, updated_at, parent_id, nombre, parent,
    } = {}) {
        this.id = id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.parent_id = parent_id;
        this.nombre = nombre
        this.parent = parent
    }
}

export default Tarea