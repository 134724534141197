class Trabajo {
  constructor({
    codigo,
    peticionario,
    encomienda,
    fecha_del_parte,
    zona_id,
    zona,
    jefe_equipo,
    lugar_de_realizacion,
    trabajo_a_realizar,
    fecha_de_inicio,
    fecha_de_fin,
    numero_empleados,
    user_id,
    otros,
    estado,
    materiales,
    trabajos_realizados,
    incidencias,
    id,
    created_at,
    updated_at,
    asignado_a,
    files,
    is_urgent,
    images,
    map_markers,
    rehacer,
    task,
  } = {}) {
    this.codigo = codigo
    this.peticionario = peticionario
    this.encomienda = encomienda
    this.fecha_del_parte = fecha_del_parte
    this.zona_id = zona_id
    this.zona = zona
    this.jefe_equipo = jefe_equipo
    this.lugar_de_realizacion = lugar_de_realizacion
    this.trabajo_a_realizar = trabajo_a_realizar
    this.fecha_de_inicio = fecha_de_inicio
    this.fecha_de_fin = fecha_de_fin
    this.numero_empleados = numero_empleados
    this.user_id = user_id
    this.otros = otros
    this.estado = estado
    this.materiales = materiales
    this.trabajos_realizados = trabajos_realizados
    this.incidencias = incidencias
    this.id = id
    this.created_at = created_at
    this.updated_at = updated_at
    this.asignado_a = asignado_a
    this.files = files
    this.is_urgent = is_urgent
    this.images = images
    this.map_markers = map_markers ?? []
    this.rehacer = rehacer
    this.task = task
  }
}

export class FileModel {
  constructor({ file, uploaded_by, id, description, created_at, updated_at, fk_partes_trabajo, filepath } = {}) {
    this.file = file
    this.filepath = filepath
    this.uploaded_by = uploaded_by
    this.id = id
    this.fk_partes_trabajo = fk_partes_trabajo
    this.description = description
    this.created_at = created_at
    this.updated_at = updated_at
  }
}

export default Trabajo
