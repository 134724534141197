class Doc {
  constructor({
    id, titulo, fk_user, file_name, created_at,
  } = {}) {
    this.id = id
    this.titulo = titulo
    this.fk_user = fk_user
    this.file_name = file_name
    this.created_at = created_at
  }
}

export default Doc
