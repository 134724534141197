class Mantenimiento {
    constructor({
        id, date_mantenimiento, zona, user, encomienda, task,
    } = {}) {
        this.id = id;
        this.zona = zona;
        this.user = user;
        this.date_mantenimiento = date_mantenimiento;
        this.encomienda = encomienda
        this.task = task
    }
}

export default Mantenimiento