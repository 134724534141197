class Zona {
    constructor({
        id, created_at, updated_at, parent_id, nombre, parent, isMantenimiento
    } = {}) {
        this.id = id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.parent_id = parent_id;
        this.nombre = nombre
        this.parent = parent
        this.isMantenimiento = isMantenimiento
    }
}

export default Zona