import { BASE_API } from '@/api/v1'
import Doc from '@/models/Doc'
import BaseController from './BaseController'

class DocController extends BaseController {
  async getAll(payload) {
    const response = await super.read(`${BASE_API}/docs-generados?page=${payload.query.page}`, payload)
    const docs = this.mapToArray(new Map(response.data.map(obj => [obj.id, new Doc(obj)])))

    return {
      total: response.total,
      data: docs,
    }
  }

  async save(object) {
    const response = await super.create(`${BASE_API}/doc-generado/save`, object)
    return response
  }

  async delete(id) {
    return super.delete(`${BASE_API}/doc-generado/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default DocController
