class Empleado {
  constructor({
    id, tlf_oficina, fk_user, fk_dpto, has_uniform, departamento_name, matricula, inicio_contrato, fin_contrato, tarjeta_tickaje, vehiculo, vehiculo_matricula, nombre, apellidos, direccion, poblacion, tipo_contrato, horario, nie, dni, email, carnet, telefono, movil, is_user, estado, created_at, updated_at,
  } = {}) {
    this.id = id
    this.fk_user = fk_user
    this.fk_dpto = fk_dpto
    this.matricula = matricula
    this.inicio_contrato = inicio_contrato
    this.fin_contrato = fin_contrato
    this.nombre = nombre
    this.apellidos = apellidos
    this.direccion = direccion
    this.poblacion = poblacion
    this.tipo_contrato = tipo_contrato
    this.tarjeta_tickaje = tarjeta_tickaje
    this.vehiculo = vehiculo
    this.vehiculo_matricula = vehiculo_matricula
    this.horario = horario
    this.nie = nie
    this.dni = dni
    this.email = email
    this.carnet = carnet
    this.telefono = telefono
    this.movil = movil
    this.is_user = is_user
    this.estado = estado
    this.created_at = created_at
    this.updated_at = updated_at
    this.departamento_name = departamento_name
    this.has_uniform = has_uniform
    this.tlf_oficina = tlf_oficina
  }
}

export default Empleado
