import { BASE_API } from '@/api/v1'
import TareaModel from '@/models/Tarea'
import BaseController from './BaseController'

class TareaController extends BaseController {
  async createTarea(object) {
    return super.create(`${BASE_API}/tarefa`, object)
  }

  async updateTarea(object) {
    return super.create(`${BASE_API}/tarefa`,object)
  }

  async getAllTarefas(payload) {
    const res = await super.read(`${BASE_API}/tarefas?page=${payload.query.page}`, payload)
    const tarefas = this.mapToArray(new Map(res.data.map(obj => [obj.id, new TareaModel(obj)])))
    return {
      total: res.total,
      data: tarefas,
    }
  }


  async delete(id) {
    return super.delete(`${BASE_API}/tarefa/delete/${id}`)
  }

  async listWithoutPaginationTarea(payload) {
    const res = await super.read(`${BASE_API}/tarefas`, payload)
    const tareas = this.mapToArray(new Map(res.map(obj => [obj.id, new TareaModel(obj)])))
    return {
      total: tareas.length,
      data: tareas,
    }
  }

  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default TareaController