import { BASE_API } from '@/api/v1'
import Proveedor from '@/models/Proveedor'
import BaseController from './BaseController'

class ProveedorController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/editar-proveedor`, object)
  }

  async update(object) {
    return super.update(BASE_API, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/proveedores?page=${payload.query.page}`, payload)
    const proveedores = this.mapToArray(new Map(res.data.map(obj => [obj.id, new Proveedor(obj)])))
    return {
      total: res.total,
      data: proveedores,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/proveedores`, payload)
    const proveedores = this.mapToArray(new Map(res.map(obj => [obj.id, new Proveedor(obj)])))
    return {
      total: proveedores.length,
      data: proveedores,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/proveedor/${id}`)
    const pro = new Proveedor(res)
    pro.estado = pro.estado === 1
    return pro
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default ProveedorController
